import type { XingletInterface } from '../../jobs-xinglet';
import { JobsXingletWrapper } from '../../jobs-xinglet';
import type { JobTeaserListItemProps } from '../job-teaser-list-item';

export type MatchingReasonsHighlightXinglet =
  XingletInterface<MatchingReasonsHighlightProps>;

export type MatchingReasonsHighlightProps = Required<
  Pick<JobTeaserListItemProps, 'matchingReasons'>
>;
export const MatchingReasonsHighlight =
  JobsXingletWrapper<MatchingReasonsHighlightProps>(
    '@xing-com/crate-jobs-components-matching-reasons-highlight'
  );
